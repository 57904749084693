import StringStepComponentSpec from '@assured/step-renderer/types/step-components/String';

import {
  StringInputVariety,
  StringInputVarietyProps,
  StringInputVarietyResult,
} from './types';

const transformDisplay = (value?: string) => {
  // 9 digit format: xx-xxxxxxx
  const cleanedValue = transformValue(value);
  return cleanedValue.length > 2
    ? `${cleanedValue.substring(0, 2)}-${cleanedValue.substring(2, 9)}`
    : cleanedValue;
};

const transformValue = (value?: string) => {
  // max 9 chars, numbers only
  return value ? value.replace(/[^0-9|\\*]/g, '').substring(0, 9) : '';
};

type FEINMaskProps = {
  value?: string;
  step_component: StringStepComponentSpec;
  updateValue: (field: string | undefined, value: string | null) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const FEINMask: React.FC<FEINMaskProps> = ({
  step_component,
  value,
  updateValue,
  ...rest
}) => {
  return (
    <input
      type="text"
      value={transformDisplay(value)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      onChange={e => {
        const clean = transformValue(e.target.value);
        updateValue(step_component.field, clean);
      }}
    />
  );
};

export const fein: StringInputVariety = ({
  step_component,
  updateValue,
}: StringInputVarietyProps): StringInputVarietyResult => {
  return {
    Component: FEINMask,
    additionalProps: { step_component, updateValue },
    narrow: !!step_component.narrow,
  };
};
