export const STATES_OPTIONS = [
  { label: 'AL • Alabama', value: 'Alabama' },
  { label: 'AK • Alaska', value: 'Alaska' },
  { label: 'AZ • Arizona', value: 'Arizona' },
  { label: 'AR • Arkansas', value: 'Arkansas' },
  { label: 'CA • California', value: 'California' },
  { label: 'CO • Colorado', value: 'Colorado' },
  { label: 'CT • Connecticut', value: 'Connecticut' },
  { label: 'DE • Delaware', value: 'Delaware' },
  { label: 'DC • District Of Columbia', value: 'District Of Columbia' },
  { label: 'FL • Florida', value: 'Florida' },
  { label: 'GA • Georgia', value: 'Georgia' },
  { label: 'HI • Hawaii', value: 'Hawaii' },
  { label: 'ID • Idaho', value: 'Idaho' },
  { label: 'IL • Illinois', value: 'Illinois' },
  { label: 'IN • Indiana', value: 'Indiana' },
  { label: 'IA • Iowa', value: 'Iowa' },
  { label: 'KS • Kansas', value: 'Kansas' },
  { label: 'KY • Kentucky', value: 'Kentucky' },
  { label: 'LA • Louisiana', value: 'Louisiana' },
  { label: 'ME • Maine', value: 'Maine' },
  { label: 'MD • Maryland', value: 'Maryland' },
  { label: 'MA • Massachusetts', value: 'Massachusetts' },
  { label: 'MI • Michigan', value: 'Michigan' },
  { label: 'MN • Minnesota', value: 'Minnesota' },
  { label: 'MS • Mississippi', value: 'Mississippi' },
  { label: 'MO • Missouri', value: 'Missouri' },
  { label: 'MT • Montana', value: 'Montana' },
  { label: 'NE • Nebraska', value: 'Nebraska' },
  { label: 'NV • Nevada', value: 'Nevada' },
  { label: 'NH • New Hampshire', value: 'New Hampshire' },
  { label: 'NJ • New Jersey', value: 'New Jersey' },
  { label: 'NM • New Mexico', value: 'New Mexico' },
  { label: 'NY • New York', value: 'New York' },
  { label: 'NC • North Carolina', value: 'North Carolina' },
  { label: 'ND • North Dakota', value: 'North Dakota' },
  { label: 'OH • Ohio', value: 'Ohio' },
  { label: 'OK • Oklahoma', value: 'Oklahoma' },
  { label: 'OR • Oregon', value: 'Oregon' },
  { label: 'PA • Pennsylvania', value: 'Pennsylvania' },
  { label: 'RI • Rhode Island', value: 'Rhode Island' },
  { label: 'SC • South Carolina', value: 'South Carolina' },
  { label: 'SD • South Dakota', value: 'South Dakota' },
  { label: 'TN • Tennessee', value: 'Tennessee' },
  { label: 'TX • Texas', value: 'Texas' },
  { label: 'UT • Utah', value: 'Utah' },
  { label: 'VT • Vermont', value: 'Vermont' },
  { label: 'VA • Virginia', value: 'Virginia' },
  { label: 'WA • Washington', value: 'Washington' },
  { label: 'WV • West Virginia', value: 'West Virginia' },
  { label: 'WI • Wisconsin', value: 'Wisconsin' },
  { label: 'WY • Wyoming', value: 'Wyoming' },
];

export default STATES_OPTIONS;
