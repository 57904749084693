import './Nav.css';

import classNames from 'classnames';
import React, { useState } from 'react';

import { useTenantConfig } from '../hooks';
import TenantLogo from './TenantLogo';

interface NavProps {
  onBack: () => void;
  tenant?: string;
  name?: string;
  noBack?: boolean;
  modal?: boolean;
  subNav?: React.ReactFragment;
}

interface ChatButtonProps {
  className: string;
  name?: string;
  children?: React.ReactFragment;
}

const TENANT_ACCENT_COLORS: Record<string, string> = {
  hanover: '#0033a0',
  statefarm: '#ed1d25',
  amfam: '#003da6',
  cinfin: '#004d71',
  kemper: '#003057',
  safeauto: '#ff0a20',
  allstate: '#0033a0',
  txfb: '#0e8ece',
  utica: '#0078ae',
  americanmodern: '#00a3d7',
  plymouthrock: '#0092d2',
  travelers: '#E01819',
  shelter: '#034393',
  csaa: '#cd1b2e',
  selective: '#FFCF31',
  usaa: '#003A63',
  crawford: '#204487',
  mercury: '#991b1e',
  countryfinancial: '#015c3e',
  autoowners: '#114477',
  farmers: '#1c428b',
  progressive: '#2D95E5',
  geico: '#0c4998',
  chubb: '#000000',
  westfield: '#EB3300',
  nycm: '#E75200',
  donegal: '#C61532',
  tokiomarineamerica: '#00A2E2',
  mapfre: '#db301a',
  westbend: '#0F2C74',
  americannational: '#ee2e25',
  nationalgeneral: '#25589D',
  hiroad: '#95d600',
  autoclubgroup: '#ed1c2d',
  vtmutual: '#B22222',
  thegeneral: '#007A53',
  meemic: '#00539F',
  liberty: '#FED000',
  libertymutual: '#FED000',
  amtrust: '#002D72',
  thehartford: '#822B2F',
  safety: '#0079b6',
  sentry: '#FED000',
  norfolk: '#F6333F',
};

const generateAccentColor = (tenant: string) => {
  const color = TENANT_ACCENT_COLORS[tenant];
  return color || null;
};

const ChatButton: React.FC<ChatButtonProps> = ({
  className,
  name,
  children,
}) => {
  const [loading, setLoading] = useState(false);

  const start = () => {
    if (window.location.href.includes('demo.')) {
      window.alert(
        `[Demo Mode] Would be launching omnichannel communication portal!`,
      );
    } else if ((window as any).webchat) {
      (window as any).Twilio.FlexWebChat.Actions.invokeAction(
        'ToggleChatVisibility',
      );
    } else {
      setLoading(true);
      const firstName = name ? name.split(' ')[0] : null;

      (window as any).Twilio.FlexWebChat.createWebChat({
        accountSid: 'AC213c227e1ccc63e952eb989030e786b3',
        flexFlowSid: 'FOc7479cfb44a075ec375420c4d1ec9272',
        context: { friendlyName: name },
        componentProps: {
          MainHeader: {
            titleText: `How can we help you${
              firstName ? `, ${firstName}` : ''
            }?`,
          },
        },
        available: true,
      }).then((webchat: any) => {
        const { manager } = webchat;
        manager.strings.WelcomeMessage = `Welcome to Assured Live Chat. We're here to help.`;
        manager.strings.PredefinedChatMessageBody = `Hi ${
          firstName || 'there'
        }! How can we help you with your claim today?`;
        webchat.init();
        (window as any).webchat = webchat;
        setLoading(false);
      });
    }
  };

  return (
    <div className={className} onClick={start}>
      {loading ? (
        <svg
          width="24"
          height="24"
          className="inline align-middle animate-spin"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        children
      )}
    </div>
  );
};

const Nav: React.FC<NavProps> = ({
  onBack,
  tenant,
  name,
  noBack,
  modal,
  subNav,
}) => {
  const tenantConfig = useTenantConfig();
  const accentColor = generateAccentColor(tenant);

  return (
    <nav
      className={classNames(`Nav p-2 fixed w-full bg-cool-gray-100 shadow`)}
      style={accentColor ? { borderBottom: `2px solid ${accentColor}` } : {}}
    >
      <div className="flex justify-between">
        {modal ? (
          <button
            className="btn btn-text"
            onClick={onBack}
            aria-label="Navigate Close"
          >
            Close
          </button>
        ) : (
          <button
            className={classNames(`btn inline-block`, noBack && `btn-disabled`)}
            disabled={noBack}
            onClick={onBack}
            aria-label="Navigate Back"
          >
            <svg
              className="inline align-middle fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <span className="sr-only">Back to previous step</span>
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
            </svg>
          </button>
        )}
        {tenant && (
          <div className="flex-1 flex items-center justify-center">
            <TenantLogo
              tenant={tenant}
              displayName={tenantConfig?.displayName}
              size="small"
              horizontal={!!modal}
              style={{ maxHeight: modal ? 30 : 50 }}
            />
          </div>
        )}
        {tenantConfig?.features.nav_enableChat ? (
          modal ? (
            <ChatButton name={name} className="btn btn-text">
              Help
            </ChatButton>
          ) : (
            <ChatButton name={name} className="btn inline-block">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="inline align-middle fill-current transform scale-150"
              >
                <path
                  d="M10.64 14.984h-.343a4.147 4.147 0 01-.719-.047 4.527 4.527 0 01-.476.422c-.245.198-.534.407-.868.625a9.02 9.02 0 01-1.054.594c-.37.177-.716.292-1.04.344l-.046.008a.337.337 0 01-.32-.102.376.376 0 01-.102-.265.305.305 0 01.031-.14c.11-.21.284-.53.508-.962.224-.432.336-.83.336-1.195a5.475 5.475 0 01-1.125-.844 4.576 4.576 0 01-.797-1.063A5.327 5.327 0 014 9.875c0-.677.17-1.312.508-1.906a4.982 4.982 0 011.398-1.547 7.194 7.194 0 012.078-1.04A8.254 8.254 0 0110.531 5c.896 0 1.737.128 2.524.383a6.988 6.988 0 012.054 1.039 5.059 5.059 0 011.383 1.547c.339.594.508 1.229.508 1.906.01.76-.146 1.456-.469 2.086a4.83 4.83 0 01-1.343 1.617c-.573.448-1.25.794-2.032 1.04a8.37 8.37 0 01-2.515.366zM20 13.906c0 .73-.177 1.373-.531 1.93-.354.557-.901 1.023-1.64 1.398v.032c0 .323.062.698.187 1.125.125.427.24.729.343.906a.305.305 0 01.032.125.318.318 0 01-.328.328h-.094c-.552-.094-1.11-.375-1.672-.844-.563-.469-.901-.776-1.016-.922-.125.01-.234.019-.328.024a5.362 5.362 0 01-.297.008h-.297a7.53 7.53 0 01-1.445-.133 6.147 6.147 0 01-1.273-.39 5.142 5.142 0 01-1.938-1.445c.021-.022 6.804.702 8.352-4.751.047-.188.08-.37.101-.547.657.385 1.128.839 1.414 1.36.287.52.43 1.12.43 1.796z"
                  fill="currentColor"
                  stroke="none"
                  stroke-width="1"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </ChatButton>
          )
        ) : (
          <div className="w-24" />
        )}
      </div>
      {subNav ? (
        <div className="mt-1 mx-2 flex justify-evenly sm:justify-center">
          {subNav}
        </div>
      ) : null}
    </nav>
  );
};

export default Nav;
