import './String.css';
import 'react-phone-number-input/style.css';

import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Props as PhoneInputProps } from 'react-phone-number-input';
//@ts-ignore
import PhoneNumberInput from 'react-phone-number-input/input';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { Tooltip } from 'react-tooltip';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { stripEmojis } from '~/src/utils';

import {
  makeAriaLabel,
  makeAriaLabeledBy,
  makeStepComponentFieldID,
} from '@assured/step-renderer/helpers/stringsUtils';

import { dataTestId } from '../../../utilities/dataTestId';
import Label from '../Label';
import IncrementableSmallNumber from './IncrementableSmallNumber';
import UnitInput from './UnitInput';
import { StringProps } from './varieties/types';

import type { StepComponentFC } from '@assured/step-renderer';

const DEFAULT_COUNTRY = 'US';

// FIXME(06-15-2020) a lot of cleaning up to do here
const String: StepComponentFC<StringProps> = ({
  step_component,
  primaryValue,
  updateValue,
  attemptSubmit,
  error,
  showErrorMessages,
  className,
  showsPrefill,
  id,
}) => {
  const correctCapitalization = (val: string) => {
    let v = val;
    if (
      [
        'license_plate',
        'insurance_policy_number',
        'insurance_claim_number',
      ].includes(step_component.mode || '') &&
      v
    ) {
      v = v.toUpperCase();
    }

    if (step_component.mode === 'insurance_policy_number') {
      v = v.replace(/[^a-zA-Z0-9\-]/g, '');
    }

    if (v?.length && step_component.mode !== 'email') {
      v = v[0].toUpperCase() + v.substring(1);
    }
    return v;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let v = e ? correctCapitalization(e.target.value) : null;
    v = stripEmojis(v);
    updateValue(step_component.field, v);
  };

  useEffect(() => {
    if (
      showsPrefill &&
      !step_component.existing_value &&
      step_component.mode === 'phone_number'
    ) {
      step_component.existing_value = '+14154567890';
    }
  }, []);

  let Component: React.ElementType = 'input';
  let additionalProps:
    | {}
    | { blockEmoji: boolean; maxlength?: number; minLength?: number }
    | PhoneInputProps<HTMLInputElement>
    | (MaskedInputProps & {
        onRawChange?: (v: number) => void;
        minimum?: number;
      })
    | {
        onRawChange?: (v: number) => void;
        subdivisions: any;
      }
    | { rows: number } = {};
  let additionalContent;
  let narrow = !!step_component.narrow;

  if (step_component.mode === 'phone_number') {
    Component = PhoneNumberInput;
    additionalProps = {
      country: DEFAULT_COUNTRY,
      // defaultCountry: DEFAULT_COUNTRY,
      value: `${primaryValue}`,
      type: 'tel',
      displayInitialValueAsLocalNumber: true,
      onChange: (v: string) => {
        // If empty, set to null to explicitly unset the value on backend
        updateValue(step_component.field, correctCapitalization(v) ?? null);
      },
      maxLength: 16,
      // countrySelectProps: { tabIndex: -1, unicodeFlags: true },
    };
  } else if (step_component.mode === 'currency') {
    Component = MaskedInput;
    const v = primaryValue ? +primaryValue : 0;

    additionalProps = {
      mask: createNumberMask({}),
      type: 'tel',
      value: v / 100 || undefined,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e ? +e.target.value.replace(/[^0-9.]/g, '') : null;
        updateValue(step_component.field, val ? Math.round(val * 100) : null);
      },
      placeholder: '$0',
      style: { textAlign: 'center' },
    };
    narrow = true;
  } else if (step_component.mode === 'paragraph') {
    Component = 'textarea';
    additionalProps = { rows: 4, blockEmoji: true };
    if (step_component.paragraph_mode === 'workers_comp_description') {
      const nChars = typeof primaryValue === 'string' ? primaryValue.length : 0;
      const GOAL_CHARS = 280;
      const message = getDescriptionMessage(nChars);

      const r = 10;
      const circleLength = 2 * Math.PI * r;
      const colored = (circleLength * nChars) / 280;
      const gray = circleLength - colored > 0 ? circleLength - colored : 0;

      additionalContent = (
        <div className="flex items-center justify-end">
          <div className="font-medium text-sm text-cool-gray-600">
            {message}
          </div>
          <svg className="ml-2 w-7 h-10 inline-block">
            <circle
              id="gray"
              cx="50%"
              cy="50%"
              r={r}
              style={{ fill: 'none', strokeWidth: 6, stroke: 'lightgray' }}
            />
            <circle
              id="colored"
              cx="50%"
              cy="50%"
              r={r}
              style={{
                fill: 'none',
                strokeWidth: 6,
                stroke: getCircleStroke(GOAL_CHARS, nChars),
                strokeDasharray: `${colored} ${gray}`,
              }}
            />
          </svg>
        </div>
      );
    }
  } else if (step_component.mode === 'distance_number') {
    Component = IncrementableSmallNumber;
    const defaultUnits = step_component.initial_distance_units || 'feet';
    const [strValue, units] = primaryValue
      ? primaryValue.toString().split(' ')
      : [undefined, defaultUnits];
    const numericValue = parseInt(strValue || '0', 10);

    const makeNumericInputCleaner = (limit: number) => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        let val: number | '' = '';
        const strVal = e ? e.target.value.replace(/[^0-9]/g, '') : '';
        val = parseInt(strVal, 10);
        if (Number.isSafeInteger(val)) {
          if (step_component.minimum && val < step_component.minimum) {
            val = step_component.minimum;
          }
          if (val > limit) {
            val = limit;
          }
        } else {
          // Non-numeric/invalid input, set to 0
          updateValue(step_component.field, `0 ${units}`);
          return;
        }
        updateValue(step_component.field, `${val} ${units}`);
      };
    };

    additionalProps = {
      mask: createNumberMask({ prefix: '' }),
      type: 'tel',
      value: numericValue,
      placeholder: '0',
      minimum: step_component.minimum,
      onChange: makeNumericInputCleaner(9999),
      onRawChange: (localV: number | string) =>
        updateValue(step_component.field, localV),
      maximum: 9999,
      initialStepSize: step_component?.step_size,
      mode: 'distance_number',
      style: { textAlign: 'center', maxWidth: 120 },
      initialUnits: units,
    };
    narrow = true;
  } else if (
    step_component.mode === 'small_number' ||
    step_component.mode === 'mph_number'
  ) {
    // To Consider: Should we refactor number/currency/etc to a separate primitive input type
    // instead of overloading String?
    Component = IncrementableSmallNumber;
    const v = primaryValue ? +primaryValue : 0;

    const makeNumericInputCleaner = (limit: number) => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        let val: number | '' = '';
        // Strip all non-numeric characters
        const strVal = e ? e.target.value.replace(/[^0-9]/g, '') : '';
        // If we're left with something numeric, use it
        if (!Number.isNaN(parseInt(strVal, 10))) {
          val = parseInt(strVal, 10);
        }
        // If val is numeric, enforce minimum and maximum values (if present)
        if (typeof val === 'number') {
          if (
            typeof step_component.minimum === 'number' &&
            val < step_component.minimum
          ) {
            val = step_component.minimum;
          }
          if (val > limit) {
            val = limit;
          }
        }
        // Update after cleaning
        updateValue(step_component.field, val);
      };
    };

    additionalProps = {
      mask: createNumberMask({ prefix: '' }),
      type: 'tel',
      value: v || v === 0 ? v : undefined,
      placeholder: '0',
      minimum: step_component.minimum,
      onChange: makeNumericInputCleaner(step_component.maximum ?? 99),
      onRawChange: (localV: number) =>
        updateValue(step_component.field, localV),
      maximum: step_component.maximum ?? 99,
      initialStepSize: step_component?.step_size,
      style: { textAlign: 'center', maxWidth: 60 },
      disabled: step_component.disabled,
      ...(step_component.mode === 'small_number'
        ? {
            mode: 'small_number',
          }
        : {}),
      ...(step_component.mode === 'mph_number'
        ? {
            mode: 'mph_number',
          }
        : {}),
    };
    narrow = true;
  } else if (step_component.mode === 'feet_inches') {
    Component = UnitInput;
    const v = primaryValue ? +primaryValue : 0;

    additionalProps = {
      value: v || undefined,
      onRawChange: (val: number) => updateValue(step_component.field, val),
      subdivisions: [
        {
          label: 'ft',
          base_value: 12,
        },
        {
          label: 'in',
          base_value: 1,
        },
      ],
      style: { textAlign: 'center' },
    };
    narrow = true;
  } else if (step_component.mode === 'hours_minutes') {
    Component = UnitInput;
    const v = primaryValue ? +primaryValue : 0;

    additionalProps = {
      value: v || undefined,
      onRawChange: (val: number) => updateValue(step_component.field, val),
      subdivisions: [
        {
          label: 'hr',
          base_value: 60,
        },
        {
          label: 'min',
          base_value: 1,
        },
      ],
      style: { textAlign: 'center' },
    };
    narrow = true;
  } else if (
    step_component.mode === 'number' ||
    step_component.mode === 'percentage'
  ) {
    Component = MaskedInput;
    const v = primaryValue ? +primaryValue : 0;

    additionalProps = {
      mask: createNumberMask({
        prefix: '',
        suffix: step_component.mode === 'percentage' ? '%' : undefined,
      }),
      type: 'tel',
      value: v || undefined,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e ? +e.target.value.replace(/[^0-9]/g, '') : null;
        updateValue(step_component.field, val ? Math.round(val) : null);
      },
      placeholder: step_component.mode === 'percentage' ? '0%' : '0',
      style: { textAlign: 'center' },
    };
    narrow = true;
  } else if (step_component.mode === 'postal_code') {
    additionalProps = {
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value.toString().replace(/\D/g, '').slice(0, 5);
        updateValue(step_component.field, val);
      },
      blockEmoji: true,
      minlength: step_component.minimum,
    };
  } else if (step_component.mode === 'password') {
    additionalProps = { type: 'password' };
  } else if (step_component.mode === 'ssn') {
    Component = MaskedInput;
    additionalProps = {
      mask: [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
      type: 'password',
    };
  } else {
    additionalProps = {
      blockEmoji: true,
      maxlength: step_component.maximum,
      minlength: step_component.minimum,
    };
  }

  const ariaLabel = makeAriaLabel(step_component);
  const ariaLabelledby = makeAriaLabeledBy(step_component);

  const stepComponentFieldID = makeStepComponentFieldID(step_component);

  return (
    <div
      className={classNames(
        className,
        'mt-4',
        narrow ? 'text-center' : 'text-left',
        step_component.textbox_class_name_override,
      )}
    >
      <Label step_component={step_component} />
      {step_component.tooltip?.content && (
        <Tooltip
          id="my-tooltip"
          anchorSelect={`#${stepComponentFieldID}`}
          content={step_component.tooltip.content}
          variant="info"
        />
      )}
      <Component
        id={stepComponentFieldID}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabel ? null : ariaLabelledby} // use aria-label as first choice, most of the time is more a accurate description
        type={step_component.mode === 'email' ? 'email' : 'text'}
        placeholder={step_component.placeholder}
        value={primaryValue || ''}
        onChange={onChange}
        className={classNames('textbox', error && 'Shake border-red-500')}
        style={narrow ? { maxWidth: 250 } : {}}
        aria-invalid={!!error}
        data-testid={dataTestId(
          `${
            !step_component.field?.includes('{')
              ? step_component.field
              : step_component.mode
          }Input`,
        )}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (
            attemptSubmit &&
            step_component.mode !== 'paragraph' &&
            step_component.mode !== 'distance_number' &&
            e.key === 'Enter'
          ) {
            attemptSubmit();
          }
        }}
        {...additionalProps}
      />
      {additionalContent}
      {error && showErrorMessages ? (
        <div className="error-message" role="alert" aria-live="assertive">
          {error}
        </div>
      ) : null}
    </div>
  );
};

const getDescriptionMessage = (nChars: number) => {
  switch (true) {
    case nChars < 10:
      return 'Start typing...';
    case nChars < 100:
      return 'Keep going...';
    case nChars < 200:
      return 'Add some more detail...';
    case nChars < 280:
      return 'Perfect!';
    default:
      return 'Oops! Too much.';
  }
};
const getCircleStroke = (
  goalChars: number,
  nChars: number,
): 'red' | 'orange' | '#3f83f8' => {
  const diff = goalChars - nChars;
  if (diff <= 0) {
    return 'red';
  }
  if (diff <= 20) {
    return 'orange';
  }
  return '#3f83f8';
};
String.stepConfig = {
  manualSubmit: true,
  controlsError: true,
};

export default String;
