import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

export type NavTabProps = DetailedHTMLProps<
  HTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  tab: string;
  isActive?: boolean;
};

export const NavTabsRow = ({
  children,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  border?: boolean;
}) => {
  return (
    <div
      className={classNames(
        'border-0 border-gray-200 flex gap-8',
        {
          'border-b-[1px]': props.border,
        },
        props.className,
      )}
    >
      {children}
    </div>
  );
};

export const NavTab = ({ children, tab, isActive, ...props }: NavTabProps) => {
  return (
    <a
      href={`#${tab}`}
      {...props}
      onClick={e => {
        e.preventDefault();
        props.onClick?.(e);
      }}
      className={classNames(
        'py-2',
        {
          'text-gray-600': !isActive,
          'border-b-2 border-indigo-600 text-indigo-600': isActive,
        },
        props.className,
      )}
    >
      {children}
    </a>
  );
};

export type NavTabsProps = {
  activeTab: string;
  onChange: (key: string) => void;
  tabs: string[];
  renderTab: (
    tab: string,
    isActive: boolean,
    onNavigate: (tab: string) => void,
  ) => React.ReactNode;
};
