import React, { useState } from 'react';

import {
  BodilyInjuryPerspective,
  BodilyInjuryRegion,
} from '@assured/shared-types/ClaimWorkflow/BodilyInjury';

import {
  BodilyInjuryGraphicBack,
  BodilyInjuryGraphicBackV2,
  BodilyInjuryGraphicFront,
  BodilyInjuryGraphicFrontV2,
} from './BodilyInjuryBase';

const SELECTED_FILL = '#f87171';

interface BodilyInjuryMapProps {
  selectedPerspective?: BodilyInjuryPerspective;
  selectedRegions: BodilyInjuryRegion[];
  onRegionSelect?: (region: BodilyInjuryRegion) => void;
  nonInteractive?: boolean;
  showMeta?: boolean;
  useBodyMapV2?: boolean;
}

const BodilyInjuryMap: React.FC<BodilyInjuryMapProps> = ({
  selectedPerspective,
  selectedRegions,
  onRegionSelect,
  nonInteractive,
  showMeta = true,
  useBodyMapV2,
}) => {
  // Generate Unique ID to prevent namespace conflict on style when multiple
  // <BodilyInjuryMap/> rendered on page.
  const [id, _] = useState(
    `BodilyInjury_${Math.random().toString(36).substring(2, 15)}`,
  );

  return (
    <div
      className="BodilyInjuryMap"
      style={{ perspective: 1000 }}
      id={id}
      onClick={
        nonInteractive
          ? undefined
          : (e: React.MouseEvent<HTMLInputElement>) => {
              const el = (e.target as HTMLInputElement).closest('[id]');
              if (el && el.id) {
                const region = el.id as BodilyInjuryRegion;
                onRegionSelect?.(region);
              }
            }
      }
    >
      <div
        className="relative"
        style={{
          paddingTop: '210%',
          transition: 'transform 1s',
          transformStyle: 'preserve-3d',
          transform: selectedPerspective === 'back' ? 'rotateY(180deg)' : '',
        }}
      >
        <div
          className="absolute inset-0"
          style={{
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',
            transform: 'rotateY(180deg)',
          }}
        >
          {useBodyMapV2 ? (
            <BodilyInjuryGraphicBackV2 />
          ) : (
            <BodilyInjuryGraphicBack />
          )}
        </div>
        <div
          className="absolute inset-0"
          style={{
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',
          }}
        >
          {useBodyMapV2 ? (
            <BodilyInjuryGraphicFrontV2 />
          ) : (
            <BodilyInjuryGraphicFront />
          )}
        </div>
      </div>
      {nonInteractive && selectedRegions.includes('back') && showMeta ? (
        <div className="flex justify-center">
          <div
            style={{ backgroundColor: SELECTED_FILL }}
            className="text-white px-2 py-0.5 rounded-md text-xs"
          >
            + Back injury
          </div>
        </div>
      ) : null}
      <style>{`
        ${(selectedRegions || [])
          .map(
            s =>
              `#${id}.BodilyInjuryMap #${s} > path, #${id}.BodilyInjuryMap path#${s}`,
          )
          .join(', ')} {
          fill: ${SELECTED_FILL};
        }
      `}</style>
    </div>
  );
};

export default BodilyInjuryMap;
