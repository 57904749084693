import { FC } from 'react';
import {
  components as ReactSelectComponents,
  GroupBase,
  GroupHeadingProps,
  GroupProps,
  MenuListProps,
  MenuProps,
  OptionProps,
} from 'react-select';

import { HighlightedText } from '../../HighlightedText';
import { IconFlatRenderer } from '../../Icon/IconFlatRenderer';
import { LocationIndicator } from '../../LocationIndicator';
import { NavTab, NavTabsRow } from '../../NavTabs/NavTabs';

import type { SelectDropdownWithTabsOption } from '../types';

// ------- Custom components for SelectDropdownLocation  ------- //
export const GroupHeadingLocation: FC<GroupHeadingProps> = (
  props: GroupHeadingProps,
) => (
  <div className="pb-0 py-1 pl-2">
    <ReactSelectComponents.GroupHeading
      className="!text-lg !font-normal !text-cool-gray-400"
      {...props}
    />
  </div>
);

export const MenuLocation: FC<
  MenuProps<
    SelectDropdownWithTabsOption,
    boolean,
    GroupBase<SelectDropdownWithTabsOption>
  >
> = ({ children, ...props }) => {
  const { selectGroupCategory, selectedGroupCategory, groupCategories } =
    props.selectProps;

  return (
    <ReactSelectComponents.Menu {...props} className="h-300">
      <div className="flex border-b border-cool-gray-300 px-3">
        <NavTabsRow border={true}>
          <NavTab
            onClick={e => {
              props?.clearValue();
              selectGroupCategory?.('all');
            }}
            key={'tab-all'}
            className="-mb-[1px] whitespace-nowrap"
            isActive={selectedGroupCategory === 'all'}
            tab={'all'}
          >
            All
          </NavTab>

          {groupCategories?.map(category => {
            return (
              <NavTab
                onClick={e => {
                  props?.clearValue();

                  selectGroupCategory?.(category);
                }}
                key={'tab-' + category}
                tab={category}
                isActive={selectedGroupCategory === category}
                className="-mb-[1px] whitespace-nowrap"
              >
                {category}
              </NavTab>
            );
          })}
        </NavTabsRow>
      </div>

      {children}
    </ReactSelectComponents.Menu>
  );
};

export const OptionLocation = (
  props: OptionProps<SelectDropdownWithTabsOption>,
) => {
  return (
    <ReactSelectComponents.Option
      className="py-4 rounded-none hover:!bg-cool-gray-100"
      {...props}
    >
      <div className="flex flex-row items-center px-2">
        {props.data.locationIndicatorKey ? (
          <LocationIndicator
            size="large"
            type={props.data.locationIndicatorKey}
          >
            {props.data.label}
          </LocationIndicator>
        ) : null}
        <div className="flex pl-4 w-full flex-wrap leading-snug">
          <div className="w-full">
            <HighlightedText
              classNameHighlighted="font-bold text-indigo-bright-600"
              input={props.data.label ?? ''}
              term={props?.selectProps?.inputValue}
            />
          </div>
          <div className=" w-full text-[0.95rem] text-cool-gray-500">
            <HighlightedText
              classNameHighlighted="font-bold text-indigo-bright-600"
              input={props.data.sublabel ?? ''}
              term={props?.selectProps?.inputValue}
            />
          </div>
        </div>
      </div>
    </ReactSelectComponents.Option>
  );
};

// ------- Custom components for default SelectDropdownWithTabs ------- //

export const GroupHeadingDefault = (props: GroupHeadingProps) => (
  <div className="pt-2 pb-3 -mx-1">
    <ReactSelectComponents.GroupHeading
      className="!text-base !font-normal !text-cool-gray-400"
      {...props}
    />
  </div>
);

export const MenuDefault: FC<
  MenuProps<
    SelectDropdownWithTabsOption,
    boolean,
    GroupBase<SelectDropdownWithTabsOption>
  >
> = ({ children, ...props }) => {
  const { selectGroupCategory, selectedGroupCategory, groupCategories } =
    props.selectProps;

  return (
    <ReactSelectComponents.Menu {...props} className="h-300">
      <div className="flex border-b border-cool-gray-300 px-3">
        <NavTabsRow border={true}>
          <NavTab
            onClick={e => {
              selectGroupCategory?.('all');
            }}
            key={'tab-all'}
            className="-mb-[1px] whitespace-nowrap"
            isActive={selectedGroupCategory === 'all'}
            tab={'all'}
          >
            All
          </NavTab>

          {groupCategories?.map(category => {
            return (
              <NavTab
                onClick={e => {
                  selectGroupCategory?.(category);
                }}
                key={'tab-' + category}
                tab={category}
                isActive={selectedGroupCategory === category}
                className="-mb-[1px] whitespace-nowrap"
              >
                {category}
              </NavTab>
            );
          })}
        </NavTabsRow>
      </div>

      {children}
    </ReactSelectComponents.Menu>
  );
};

// ------- Custom components shared ------- //

export const Group: FC<GroupProps<SelectDropdownWithTabsOption, false>> = (
  props: GroupProps<SelectDropdownWithTabsOption, false>,
) => (
  <div className="border-b border-cool-gray-300 py-1 last:border-transparent">
    <ReactSelectComponents.Group {...props} />
  </div>
);

export const MenuList = (
  props: MenuListProps<
    SelectDropdownWithTabsOption,
    false,
    GroupBase<SelectDropdownWithTabsOption>
  >,
) => {
  return (
    <ReactSelectComponents.MenuList {...props} className="-mx-1">
      {props.children}
    </ReactSelectComponents.MenuList>
  );
};

export const DropdownIndicator = () => (
  <div className="flex gap-1.5 items-center ml-3">
    <div className="flex items-center bg-cool-gray-100 px-2.5 rounded-md aspect-square">
      <IconFlatRenderer
        width="16"
        style={{
          fill: 'red',
        }}
        iconKey={'ICON_FLAT_ARROW_DOWN'}
        className="rotate-180 [&_path]:stroke-cool-gray-600"
      />
    </div>
    <div className="flex items-center bg-cool-gray-100 px-2.5 rounded-md aspect-square">
      <IconFlatRenderer
        className="[&_path]:stroke-cool-gray-600"
        width="16"
        iconKey={'ICON_FLAT_ARROW_DOWN'}
      />
    </div>
    <div className="text-cool-gray-500/90 px-2 text-sm">to navigate</div>
  </div>
);

export const NoOptionsMessage = () => (
  <div className="py-20 flex text-cool-gray-400 justify-center">
    No results found
  </div>
);
