import { ReactNode, useCallback, useEffect, useState } from 'react';

import createCache, { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

/**
 * Emotion `CacheProvider` to ensure styles are provided in various states
 * of a UI. To be clear, we don't use Emotion in our source code, but certain
 * dependencies like React Select do. Our initial, specific use for it is to
 * fix a bug in which Emotion style tags (`<style data-emotion ... />) were
 * being lost, sometimes intermittently based on UI events and another report
 * based on a specific environment build (staging). These tags were rendered
 * in the `head` tag. We can leverage `stylesRoot` below to render style tags
 * colocated with the rendered component, in the document body (ideally
 * prepended, so styles are interpreted before HTML).
 * @see {@link https://linear.app/assured/issue/ENG-7766/[adjuster][bug]-general-availability-modal-and-emma-assignment | ENG-7766}
 * @see {@link https://emotion.sh/docs/cache-provider | Emotion `CacheProvider`}
 * @see {@link https://emotion.sh/docs/@emotion/cache#container | Emotion Docs - @emotion/cache}
 * @see {@link https://github.com/JedWatson/react-select/issues/3680#issuecomment-809518245 | React Select GitHub Issue that inspired this solution}
 */
export const EmotionStyleSheetProvider = ({
  cacheKey = 'emotion-react-select',
  children,
}: {
  /** The `key` used by `@emotion/cache` `createCache` */
  cacheKey?: string;

  /** The `ReactNode` tree that correspond to Emotion built styles */
  children: ReactNode;
}) => {
  const [cache, setCache] = useState<EmotionCache | null>(null);
  const [stylesRoot, setStylesRoot] = useState<HTMLDivElement | null>(null);

  // once we have a `ref` trigger state change and set `stylesRoot`
  const handleRef = useCallback(node => {
    setStylesRoot(node);
  }, []);

  useEffect(() => {
    setCache(
      createCache({
        container: stylesRoot ?? undefined,
        key: cacheKey,
        prepend: true,
      }),
    );
  }, [cacheKey, stylesRoot]);

  return !cache ? null : (
    <CacheProvider value={cache}>
      {/**
       * ensure we inect React Select Emotion styles in an element
       * that is static (attributes don't change)
       */}
      <div
        ref={handleRef}
        // ensure this div takes no real estate or blocks any UI
        className="w-1 h-1 absolute -left-80"
        data-id={`${cacheKey}__style-root`}
      />
      {children}
    </CacheProvider>
  );
};
