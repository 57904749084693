// This is a good first pass at refactoring the string input.  Ideally we would have
// the following. Each of these varieties would be their own input Component that handled
// it's own id, datatest-id, state and whatever else.  Then the String component would
// wrap the label, the component and the validation message ina containing div.  Not unlike this,
// but instead of returning various props and adding them to an instance of the abstracted Component
// it would simply call the abstracted component with a small set of properties that are the same
// for each variety.  Thus obfuscating ALL of the implementation with in the component
// and removing the need for a an intermediary component to accept the configuration.
// Two current examples that would benifit from this are Paragraph and SSNumber

import 'react-phone-number-input/style.css';
import './String.css';

import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import { stripEmojis } from '~/src/utils';

import {
  makeAriaLabel,
  makeAriaLabeledBy,
  makeStepComponentFieldID,
} from '@assured/step-renderer/helpers/stringsUtils';

import { dataTestId } from '../../../utilities/dataTestId';
import Label from '../Label';
import { varieties } from './varieties';
import {
  StringComponentState,
  StringInputVariety,
  StringProps,
} from './varieties/types';
import { correctCapitalization } from './varieties/utilities';

import type { StepComponentFC } from '@assured/step-renderer';

export const String: StepComponentFC<StringProps> = ({
  step_component,
  primaryValue,
  updateValue,
  attemptSubmit,
  error,
  showErrorMessages,
  className,
  showsPrefill,
}) => {
  const componentState = useRef<StringComponentState>({
    currency: { formattedValue: '' },
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let v = e ? correctCapitalization(step_component, e.target.value) : null;
    v = stripEmojis(v);
    updateValue(step_component.field, v);
  };

  // This is just used for DEMO and it would be great to remove it somehow someday
  useEffect(() => {
    if (
      showsPrefill &&
      !step_component.existing_value &&
      step_component.mode === 'phone_number'
    ) {
      // eslint-disable-next-line no-param-reassign
      step_component.existing_value = '+14154567890';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let varietyProperties;
  if (step_component.mode) {
    varietyProperties = varieties[
      step_component.mode as keyof typeof varieties
    ] as StringInputVariety;
  }
  let result;
  if (!varietyProperties) {
    result = {
      Component: 'input',
      additionalProps: {
        blockEmoji: true,
        maxLength: step_component.maximum,
        minLength: step_component.minimum,
      },
      narrow: !!step_component.narrow,
    };
  } else {
    result = varietyProperties({
      step_component,
      primaryValue,
      updateValue,
      componentState,
    });
  }
  const { Component, additionalProps, additionalContent, narrow } = result;

  const ariaLabel = makeAriaLabel(step_component);
  const ariaLabelledby = makeAriaLabeledBy(step_component);

  const stepComponentFieldID = makeStepComponentFieldID(step_component);

  return (
    <div
      className={classNames(
        className,
        'mt-4',
        narrow ? 'text-center' : 'text-left',
        step_component.textbox_class_name_override,
      )}
    >
      <Label step_component={step_component} />
      {step_component.tooltip?.content && (
        <Tooltip
          id="my-tooltip"
          anchorSelect={`#${stepComponentFieldID}`}
          content={step_component.tooltip.content}
          variant="info"
        />
      )}
      <Component
        id={stepComponentFieldID}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabel ? null : ariaLabelledby} // use aria-label as first choice, most of the time is more a accurate description
        type={step_component.mode === 'email' ? 'email' : 'text'}
        placeholder={step_component.placeholder}
        value={primaryValue || ''}
        onChange={onChange}
        className={classNames('textbox', error && 'Shake border-red-500')}
        style={narrow ? { maxWidth: 250 } : {}}
        aria-invalid={!!error}
        data-testid={dataTestId(
          `${
            !step_component.field?.includes('{')
              ? step_component.field
              : step_component.mode
          }Input`,
        )}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (
            attemptSubmit &&
            step_component.mode !== 'paragraph' &&
            step_component.mode !== 'distance_number' &&
            e.key === 'Enter'
          ) {
            attemptSubmit();
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...additionalProps}
      />
      {additionalContent}
      {error && showErrorMessages ? (
        <div className="error-message" role="alert" aria-live="assertive">
          {error}
        </div>
      ) : null}
    </div>
  );
};

String.stepConfig = {
  manualSubmit: true,
  controlsError: true,
};
