import { FC, useState } from 'react';
import { GroupBase, GroupProps, MenuProps } from 'react-select';

import { SelectDropdown } from '../';
import {
  DropdownIndicator,
  Group,
  GroupHeadingDefault,
  MenuDefault,
  NoOptionsMessage,
} from './components';

import type { SelectDropdownWithTabProps } from '../types';
export const SelectDropdownWithTabs: FC<SelectDropdownWithTabProps> = ({
  groupedOptions,
  components,
  defaultMenuIsOpen = false,
  closeMenuOnSelect = false,
  autoFocus = true,
  menuPlacement = 'bottom',
  maxMenuHeight = 300,
  minMenuHeight = 300,
  openMenuOnFocus = true,
  openMenuOnClick = true,
  onChange,
  value,
}) => {
  const [selectedGroupCategory, setSelectedGroupCategory] =
    useState<string>('all');

  const selectedGroup = groupedOptions?.find(
    o => o?.category === selectedGroupCategory,
  );

  const groupCategories = groupedOptions.map((g, i) => {
    return g.category as string;
  });

  return (
    <SelectDropdown
      autoFocus={autoFocus}
      closeMenuOnSelect={closeMenuOnSelect}
      components={{
        DropdownIndicator,
        Group: Group as FC<GroupProps<unknown, boolean, GroupBase<unknown>>>,
        GroupHeading: GroupHeadingDefault,
        Menu: MenuDefault as FC<
          MenuProps<unknown, boolean, GroupBase<unknown>>
        >,
        NoOptionsMessage,
        ...components,
      }}
      defaultMenuIsOpen={defaultMenuIsOpen}
      groupCategories={groupCategories}
      isSearchable
      maxMenuHeight={maxMenuHeight}
      minMenuHeight={minMenuHeight}
      menuPlacement={menuPlacement}
      openMenuOnClick={openMenuOnClick}
      openMenuOnFocus={openMenuOnFocus}
      options={selectedGroup ? [selectedGroup] : groupedOptions} // `groupedOptions` for collated data in `all` tab, else just show specific category
      groupedOptions={groupedOptions} // slices of `options` passed to options, groupedOptions is used to check the value in ALL the categories
      selectedGroupCategory={selectedGroupCategory}
      selectGroupCategory={setSelectedGroupCategory}
      value={value}
      onChange={onChange}
    />
  );
};
